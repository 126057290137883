<section class="footer">
  <div class="row" style="height: 100%">
    <div class="col-sm-12 footer-content col-address">
      <div class="div-social-wrap">
        <ul class="div-social-media st-mp0">
          <li>
            <a href="mailto:info@martadev.com"
              ><i class="fas fa-envelope"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
