<nav
  class="
    navbar navbar-expand-lg navbar-light
    shadow-sm
    bg-light
    nav-menu
    fixed-top
  "
>
  <div class="container">
    <a class="navbar-brand d-flex align-items-center" href="#">
      <img class="logo" src="assets/img/logo.png" alt="MartaDev" />
    </a>
    <button
      class="navbar-toggler navbar-toggler-right border-0"
      type="button"
      data-toggle="collapse"
      data-target="#navbar4"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbar4">
      <ul class="navbar-nav mr-auto pl-lg-4">
        <li class="navbar-item">
          <a class="nav-link active" href="#home">
            <span class="d-inline-block d-lg-none icon-width"
              ><i class="fas fa-home"></i></span
            >Inicio
          </a>
        </li>
        <li class="navbar-item">
          <a class="nav-link" href="#about">
            <span class="d-inline-block d-lg-none icon-width"
              ><i class="fas fa-user"></i></span
            >Sobre mí
          </a>
        </li>
        <li class="navbar-item">
          <a class="nav-link" href="#apps">
            <span class="d-inline-block d-lg-none icon-width"
              ><i class="fas fa-mobile-alt"></i></span
            >Portfolio</a
          >
        </li>
        <li class="navbar-item">
          <a class="nav-link" href="#privacy">
            <span class="d-inline-block d-lg-none icon-width"
              ><i class="fas fa-lock"></i></span
            >Política de privacidad</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="text-center text-white bg-home">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 main-header">
        <h1 class="main-title animate__animated animate__fadeInUp">
          Marta <br />Dev
        </h1>
        <div class="main-border">
          <span class="bar-left animate__animated animate__fadeInUp"></span>
          <span class="bar-right animate__animated animate__fadeInUp"></span>
        </div>
        <p class="main-subtitle animate__animated animate__fadeInDownBig">
          Software developer
        </p>
      </div>
    </div>
  </div>
</div>

<section id="about" class="animate__animated animate__fadeInUp">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-xs-mobile-fullwidth">
        <div class="section-wrapper">
          <span class="section-title"
            >DESARROLLO DE APLICACIONES IOS Y ANDROID
            <div class="main-border">
              <span class="bar-left animate__animated animate__fadeInUp"></span>
            </div>
          </span>

          <h1 class="">
            Mi nombre es Marta Jurado, <br />
            software developer.
          </h1>

          <p class="section-description">
            He creado MartaDev como una inquietud por desarrollar y mejorar mis
            capacidades como desarrolladora de software.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="apps" class="animate__animated animate__fadeInUp">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-xs-mobile-fullwidth">
        <div class="section-wrapper no-padding">
          <span class="section-title"
            >PORTFOLIO
            <div class="main-border">
              <span class="bar-left animate__animated animate__fadeInUp"></span>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row app_section no-margin">
    <div class="col-sm-12 no-padding">
      <div class="section-app">
        <div class="row no-margin">
          <div class="col-sm-6 app-train_brain no-padding">
            <img class="img-train_brain" src="assets/img/train_brain.png" />
          </div>
          <div class="col-sm-6 no-padding center-content">
            <p class="app-description">
              <b>Train Brain - Entrena tu memoria</b> es un juego que pone a
              prueba tu capacidad de concentración y memoria. Mejorar la memoria
              es una buena práctica que ayuda a mantener en buena forma tus
              habilidades cognitivas.
            </p>
            <p class="app-description">
              Memoriza las secuencias de imágenes que aparecen en pantalla e
              introdúcelas en el orden correcto para superar cada nivel.
            </p>
            <p class="link-p">
              <a href="#privacy">Política de privacidad</a>
            </p>

            <div class="buttons-content">
              <!--<a class="app-btn blu flex vert" href="http:apple.com">
                <i class="fab fa-apple"></i>
                <p>
                  Disponible en el <br />
                  <span class="big-txt">App Store</span>
                </p>
              </a>-->
              <a
                class="app-btn blu flex vert"
                href="https://play.google.com/store/apps/details?id=com.martadev.train_brain"
                target="_blank"
              >
                <i class="fab fa-google-play"></i>
                <p>
                  Disponible en <br />
                  <span class="big-txt">Google Play</span>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row app_section no-margin">
    <div class="col-sm-12 no-padding">
      <div class="section-app">
        <div class="row no-margin">
          <div class="col-sm-6 no-padding center-content">
            <p class="app-description">
              <b>My Space Survival</b> es una versión personalizada del famoso
              videojuego Space Invaders
            </p>
            <p class="app-description">
              Arrastra la nave espacial de un lado a otro para moverte y
              presiona la pantalla para disparar el láser que destruirá a las
              naves enemigas.
            </p>
            <p class="link-p">
              <a href="#privacy">Política de privacidad</a>
            </p>

            <div class="buttons-content">
              <a
                class="app-btn blu flex vert"
                href="https://apps.apple.com/app/id1561481245"
              >
                <i class="fab fa-apple"></i>
                <p>
                  Disponible en el <br />
                  <span class="big-txt">App Store</span>
                </p>
              </a>
            </div>
          </div>

          <div class="col-sm-6 app-space_survivor no-padding">
            <img
              class="img-space_survivor"
              src="assets/img/space_survivor.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row app_section no-margin">
    <div class="col-sm-12 no-padding">
      <div class="section-app">
        <div class="row no-margin">
          <div class="col-sm-6 no-padding">
            <img
              class="img-space_survivor"
              src="assets/img/TalesOfSeleneGraphics.png"
            />
          </div>
          <div class="col-sm-6 no-padding center-content">
            <p class="app-description">
              <b>Tales Of Selene - Platformer</b> te transporta a los mundos de
              fantasia de Selene, nuestra protagonista, donde encontrarás todo
              tipo de enemigos y monstruos a los que deberas esquivar o derrotar
              para llegar a tu destino.
            </p>
            <p class="app-description">
              Este juego de plataformas 2D será todo un reto para los jugadores
              amantes de los desafíos, ya que todo se plantea como una única
              partida donde deberás superar los niveles disponibles para
              completar con éxito tu aventura.
            </p>
            <p class="app-description">
              Salta, corre, ataca, esquiva y colecciona objetos y monedas a
              medida que avanzas manejando a Selene.
            </p>
            <p class="link-p">
              <a href="#privacy">Política de privacidad</a>
            </p>

            <div class="buttons-content">
              <a
                class="app-btn blu flex vert"
                href="https://play.google.com/store/apps/details?id=com.martadev.talesofselene"
                target="_blank"
              >
                <i class="fab fa-google-play"></i>
                <p>
                  Disponible en <br />
                  <span class="big-txt">Google Play</span>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="privacy" class="animate__animated animate__fadeInUp">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-xs-mobile-fullwidth">
        <div class="section-wrapper">
          <span class="section-title"
            >POLÍTICA DE PRIVACIDAD
            <div class="main-border">
              <span class="bar-left animate__animated animate__fadeInUp"></span>
            </div>
          </span>

          <p class="">
            Esta política de privacidad establece el uso de las siguientes
            aplicaciones móviles, creadas y mantenidas por MartaDev listadas en
            el siguiente enlace:
            <a class="link-section" href="/#apps"
              >https://martadev.com/#apps/</a
            >
          </p>

          <p>
            Al descargar, acceder y/o jugar a nuestros juegos, o interactuar con
            nuestros sitios web u otros Servicios, usted manifiesta su
            conformidad a que se recoja y use su información de acuerdo con esta
            Política de Privacidad, y presta su consentimiento para que MartaDev
            y nuestros socios de publicidad usen sus datos para fines de
            publicidad dirigida tal y como se describen en las seccion de
            RECOLECCIÓN AUTOMÁTICA DE DATOS Y PUBLICIDAD. Esas secciones también
            explican cómo puede retener o retirar su consentimiento para el uso
            de sus datos con fines publicitarios dirigidos. Si tiene algún otro
            problema respecto a brindarnos información o a utilizarla tal como
            se describe en esta Política de Privacidad, no debe utilizar
            nuestros juegos u otros Servicios.
          </p>

          <p>
            Esta Política de Privacidad se actualizó por última vez el
            17.10.2021.
          </p>

          <h5>¿QUÉ INFORMACIÓN OBTIENE LA APLICACIÓN Y CÓMO ES UTILIZADA?</h5>

          <p class="bold">Información proveída por el usuario</p>
          <p>
            La aplicación no requiere ningún tipo de registro con MartaDev, pero
            si requiere que el usuario mantenga una cuenta de Apple App Store
            (iOS) y/o una cuenta de Google Play Store (Android) para descargar
            el o las aplicaciones y utilizar sus servicios.
          </p>

          <p class="bold">Información obtenida automáticamente</p>
          <p>
            Adicionalmente, la aplicación puede recopilar de forma automática
            otro tipo de información, como ser: tipo de dispositivo móvil, país
            del usuario, dirección IP, el sistema operativo del dispositivo
            móvil e información de cómo se usa la aplicación.
          </p>
          <p>
            En MartaDev no guardarmos ni almacenamos información personal del
            usuario. Físicamente, no disponemos de servidores donde almacenar
            ningun tipo de información.
          </p>
          <p>
            Adicionalmente, en el dispositvo físico del usuario, se almacenan
            datos que permiten guardar su progreso, o premios conseguidos. En caso de la desinstalación
            de la aplicación y/o juego, esos premios conseguidos, así como el progreso, se perderán y no serán recuperables.
            El único responsable de esos
            datos es el propio usuario. En MartaDev no nos hacemos responsables
            de la pérdida o daño de los datos guardados en el dispositivo del
            usuario.
          </p>

          <h5>RECOLECCIÓN AUTOMÁTICA DE DATOS Y PUBLICIDAD</h5>
          <p>
            Puedo trabajar con anunciantes y redes de anunciantes de terceros,
            quienes necesitan saber como usted interactúa con la publicidad
            presente en la aplicación, quienes ayudan a mantener los precios
            bajos. Los anunciantes y las redes de anunciantes pueden utilizar
            parte de la información recopilada por la aplicación.
          </p>

          <p>
            Dentro de la aplicación, en la sección de "Settings", podrá escoger
            si desea publicidad personalizada o publicidad no personalizada.
          </p>

          <p>
            Si opta por excluirse de la publicidad basada en intereses, podrá
            seguir jugando a nuestros juegos y es posible que aún vea publicidad
            en ellos, pero esos anuncios no se orientarán según la información
            que tengamos sobre usted o que nuestros asociados publicitarios
            recopilen sobre usted de nuestros juegos. Sin embargo, es posible
            que aún se muestren anuncios según criterios contextuales, como el
            juego en el que se muestran o la región en la que se encuentra su
            dispositivo.
          </p>

          <h5>
            ¿PUEDEN TERCEROS OBTENER ACCESO A LA INFORMACIÓN OBTENIDA POR LA
            APLICACIÓN?
          </h5>
          <p>
            Sí. Puedo compartir la información proporcionada por el usuario o la
            obtenida automáticamente en los siguientes casos:
          </p>

          <p>- Cuando sea requerido por la ley.</p>

          <p>
            - Cuando creamos que es necesario para proteger nuestros derechos,
            proteger su seguridad o la seguridad de otros, investigar fraude o
            en respuesta a una solicitud del gobierno.
          </p>

          <p>
            - Con proveedores que trabajan en nuestro nombre y que han aceptado
            regirse por las reglas establecidas en esta política de privacidad.
          </p>

          <p>
            - Con anunciantes y redes de anuncios de terceros, así como se
            describe en la sección “ Información obtenida automáticamente”.
          </p>

          <h5>
            ¿LA APLICACIÓN OBTIENE LA INFORMACIÓN PRECISA Y EN TIEMPO REAL DEL
            DISPOSITIVO?
          </h5>
          <p>
            La aplicación no recopila información precisa y en tiempo real de la
            ubicación del dispositivo en ningún momento.
          </p>

          <h5>ACCESO A NUESTROS SERVICIOS</h5>
          <p>
            Usted es responsable de los cargos de conexión a Internet o conexión
            desde un dispositivo móvil que pueda tener por acceder a nuestros
            Servicios y utilizarlos. Si no está seguro de cuáles serán esos
            cargos, antes de acceder a nuestros Servicios o utilizarlos debería
            consultar con su proveedor de internet u operador móvil.
          </p>
          <p>
            Puede suceder que nuestros Servicios o cualquier parte de ellos no
            estén disponibles por motivos técnicos o de mantenimiento, ya sean
            estos programados o no.
          </p>
          <p>
            No garantizamos que cualquiera de nuestros Servicios esté disponible
            o libre de errores en toda situación y circunstancia o en algún
            momento concreto
          </p>

          <h5>CAMBIOS</h5>
          <p>
            Esta Política de Privacidad puede ser actualizada de vez en cuando
            por cualquier razón. Le notificaremos de cualquier cambio en nuestra
            política de privacidad mediante la publicación de la nueva política
            de privacidad en esta página.
          </p>

          <h5>CONTACTO</h5>
          <p>
            Para cualquier duda relacionada con la política de privacidad, por
            favor, ponte en contacto con nosotros:
            <a class="link-section" href="mailto:info@martadev.com"
              >info@martadev.com</a
            >
          </p>

          <p class="section-description"></p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
