<nav
  class="navbar navbar-expand-lg navbar-light shadow-sm bg-light nav-menu fixed-top"
>
  <div class="container">
    <a class="navbar-brand d-flex align-items-center" href="#">
      <img class="logo" src="assets/img/logo.png" alt="MartaDev" />
    </a>
    <button
      class="navbar-toggler navbar-toggler-right border-0"
      type="button"
      data-toggle="collapse"
      data-target="#navbar4"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbar4">
      <ul class="navbar-nav mr-auto pl-lg-4">
        <li class="navbar-item">
          <a class="nav-link active" href="/">
            <span class="d-inline-block d-lg-none icon-width"
              ><i class="fas fa-home"></i></span
            >Inicio
          </a>
        </li>
        <li class="navbar-item">
          <a class="nav-link" href="/#about">
            <span class="d-inline-block d-lg-none icon-width"
              ><i class="fas fa-user"></i></span
            >Sobre mí
          </a>
        </li>
        <li class="navbar-item">
          <a class="nav-link" href="/#apps">
            <span class="d-inline-block d-lg-none icon-width"
              ><i class="fas fa-mobile-alt"></i></span
            >Portfolio</a
          >
        </li>
        <li class="navbar-item">
          <a class="nav-link" href="/privacy">
            <span class="d-inline-block d-lg-none icon-width"
              ><i class="fas fa-lock"></i></span
            >Política de privadidad</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="text-center text-white bg-home">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 main-header">
        <h1 class="main-title animate__animated animate__fadeInUp">
          Marta <br />Dev
        </h1>
        <div class="main-border">
          <span class="bar-left animate__animated animate__fadeInUp"></span>
          <span class="bar-right animate__animated animate__fadeInUp"></span>
        </div>
        <p class="main-subtitle animate__animated animate__fadeInDownBig">
          Software developer
        </p>
      </div>
    </div>
  </div>
</div>

<section id="about" class="animate__animated animate__fadeInUp">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-xs-mobile-fullwidth">
        <div class="section-wrapper">
          <span class="section-title"
            >POLÍTICA DE PRIVACIDAD
            <div class="main-border">
              <span class="bar-left animate__animated animate__fadeInUp"></span>
            </div>
          </span>

          <p class="">
            Esta política de privacidad establece el uso de las siguientes
            aplicaciones móviles, creadas y mantenidas por MartaDev listadas en
            el siguiente enlace:
            <a class="link-section" href="/#apps">http://martadev.com/#apps/</a>
          </p>

          <h5>
            ¿QUÉ INFORMACIÓN OBTIENE EL LA APLICACIÓN Y CÓMO ES UTILIZADA?
          </h5>

          <p class="bold">Información proveída por el usuario</p>
          <p>
            La aplicación no requiere ningún tipo de registro con MartaDev, pero
            si requiere que el usuario mantenga una cuenta de Apple App Store
            (iOS) y/o una cuenta de Google Play Store (Android) para descargar
            el o las aplicaciones y utilizar sus servicios.
          </p>

          <h5>
            ¿LA APLICACIÓN OBTIENE LA INFORMACIÓN PRECISA Y EN TIEMPO REAL DEL
            DISPOSITIVO?
          </h5>
          <p>
            La aplicación no recopila información precisa y en tiempo real de la
            ubicación del dispositivo en ningún momento.
          </p>

          <h5>CAMBIOS</h5>
          <p>
            Esta Política de Privacidad puede ser actualizada de vez en cuando
            por cualquier razón. Le notificaremos de cualquier cambio en nuestra
            política de privacidad mediante la publicación de la nueva política
            de privacidad en esta página.
          </p>

          <p class="section-description"></p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
